<template>
  <FocusTrap>
  <div id="mycard" >

    <div class="card-body" style="background-color: whitesmoke;padding: 0px;">

      <div class="card-header header-elements-inline" >
        <h5 class="card-title"> Doc No: {{invoice.doc_no}}  Sale Invoice Preview</h5>
        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="print" @click="print"></a>
            <a class="list-icons-item" data-action="remove" @click="closeThis"></a>
          </div>
        </div>
      </div>


        <div class="card-body" id="printarea" >
          <header class="clearfix">
            <h1>INVOICE : {{invoice.ref_no}}</h1>
            <div id="company" class="clearfix">
              <div>Amaravathi Textile</div>
              <div>9-D Ramakrishnapuram<br /> Karur - 639002 TN</div>
              <div>04324-230620</div>
              <div><span class="text-blue-600">GSTIN : </span>33AAFFA9673E1ZH</div>
            </div>
            <div id="project" >
              <div><span>CLIENT</span> {{invoice.ledger.name}}</div>
              <div><span>ADDRESS</span> {{invoice.ledger.street}}, {{invoice.ledger.city}} {{invoice.ledger.pincode}}</div>
              <div><span>DATE</span> {{dateFormat(invoice.doc_date)}}</div>
              <div><span>DUE DATE</span> {{dateFormat(invoice.ref_date)}}</div>
            </div>
          </header>
          <main>
            <table>
              <thead>
                <tr>
                  <th class="service">Item</th>
                  <th class="service">Design</th>
                  <th class="desc">Color</th>
                  <th class="desc">Quality</th>
                  <th class="desc">Style</th>
                  <th class="unit text-right">UNIT</th>
                  <th class="unit text-right">PRICE</th>
                  <th class="qty text-right">QTY</th>
                  <th class="qty text-right">WEIGHT</th>
                  <th class="qty text-right">TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(detail,index) in invoice.list" >
                  <td class="service">{{detail.item.group.name}}</td>
                  <td class="service">{{detail.item.design.name}}</td>
                  <td class="desc">{{detail.item.color.name}}</td>
                  <td class="desc">{{detail.item.quality.name}}</td>
                  <td class="desc">{{detail.item.style.name}}</td>
                  <td class="unit">{{detail.item.unit.name}}</td>
                  <td class="unit">₹{{detail.rate}}</td>
                  <td class="qty">{{detail.qty}}</td>
                  <td class="qty">{{detail.weight}}</td>
                  <td class="total">₹1,040.00</td>
                </tr>

                <tr>
                  <td colspan="9">SUBTOTAL</td>
                  <td class="total">₹5,200.00</td>
                </tr>
                <tr>
                  <td colspan="9">TAX 5%</td>
                  <td class="total">₹1,300.00</td>
                </tr>
                <tr>
                  <td colspan="9" class="grand total">GRAND TOTAL</td>
                  <td class="grand total">₹6,500.00</td>
                </tr>
              </tbody>
            </table>
            <div id="notices">
              <div>NOTICE:</div>
              <div class="notice">A finance charge of 1.5% will be made on unpaid balances after 30 days.</div>
            </div>
          </main>

          <footer>
            Invoice was created on a computer and is valid without the signature and seal.
          </footer>

        </div>

    </div>

  </div>
  </FocusTrap>
</template>

<script>
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'
  import VRuntimeTemplate from "v-runtime-template";

  export default {
    name: 'SaleInvoicePreview',
    store,
    components:{
      VRuntimeTemplate
    },
    props: {
      invoice: {
        type: Object,
        default: () => JSON.parse('{"id":0,"ver":0,"status":0,"is_cr":"Y","type":0,"finyear":2018,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":0,"type":0,"group_code":0,"name":"","print_name":"","street":"","city":"","pin":"","state_id":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":0,"name":"","nature":0,"p_name":"","sys_obj":false},"remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}')
      }
    },
    data(){
      return {
        rowQtyTotal:0.0,
        rowAmountTotal: 0.0,
        rowIgstTotal:0.0,
        rowCgstTotal:0.0,
        rowSgstTotal:0.0,
        rowTaxableTotal:0.0,
        invtype:'',
        ledgers:[],
        totalWeight:0,
        totalAmount:0,
        totalQty:0,
        voucher: JSON.parse('{"id":0,"status":0,"finyear":0,"ledger":{"id":0,"status":0,"type":0,"name":"","alias":"","print_name":"","gstin":""},"instrument":"","pay_date":"0001-01-01","amount":0,"tds_amt":0,"remarks":""}'),
        template: `  <div id="mycard" >

    <div class="card-body" style="background-color: whitesmoke;padding: 0px;">

      <div class="card-header header-elements-inline bg-grey" >
        <h5 class="card-title"> Doc No: {{voucher.doc_no}}  Sale Invoice Preview</h5>
        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="remove" @click="closeThis"></a>
          </div>
        </div>
      </div>

        <div class="card-header header-elements-inline">
          <h5 class="card-title">Sale Invoice Preview</h5>
        </div>


        <div class="card-body">


          <div class="row">

            <div class="col-md-3">
              <div class="form-group form-group-material">
                <label class="control-label font-weight-semibold">Buyer</label>
                <input type="text" class="form-control" v-if="invoice" v-model="invoice.ledger.name" readonly>
              </div>
            </div>

            <div class="col-md-1">
              <div class="form-group form-group-material">
                <label class="control-label font-weight-semibold">Type</label>
                <input type="text" class="form-control" v-if="invoice" v-model="invtype" readonly>
              </div>
            </div>

            <div class="col-md-5">

            </div>

            <div class="col-md-1">
              <div class="form-group form-group-material">
                <label class=" control-label font-weight-semibold">Invoice No</label>
                <input type="text" class="form-control" v-if="invoice" v-model="invoice.ref_no" readonly>
              </div>
            </div>


            <div class="col-md-2">
              <div class="form-group form-group-material">
                <label class="control-label  font-weight-semibold">Invoice Date</label>
                <input type="date" class="form-control" v-if="invoice" v-model="invoice.ref_date" readonly >
              </div>
            </div>

          </div>

          <div class="row">
            <!--  Detail Table -->
            <div class="table-responsive">

              <table id="mytable" class="table table-no-bordered">
                <thead style="background-color: lightgrey">
                <tr>
                  <th style="width:50px;">S.No</th>
                  <th >Item</th>
                  <th style="width:100px; text-align: right;">GST%</th>
                  <th style="width:100px; text-align: right;">Rate</th>
                  <th style="width:75px; text-align: right;">Qty</th>
                  <th style="width:100px; text-align: right;">Taxable</th>
                  <th style="width:100px; text-align: right;">IGST</th>
                  <th style="width:100px; text-align: right;">CGST</th>
                  <th style="width:100px; text-align: right;">SGST</th>
                  <th style="width:150px; text-align: right;">Net Amount</th>
                </tr>

                </thead>
                <tbody v-for="(detail,index) in invoice.list" >
                <tr style="padding: 0px; height: 40px;">
                  <td style="padding: 0px;text-align: center;"> {{index + 1}} </td>

                  <td style="padding: 0px;">
                    {{ detail.item.name}}
                  </td>

                  <td style="padding: 0px;text-align: right;padding-right: 20px;">
                    {{detail.gst_rate}}
                  </td>

                  <td style="padding: 0px;text-align: right;padding-right: 20px;">
                    {{ indianFormat(detail.rate)}}
                  </td>

                  <td style="padding: 0px;text-align: right;padding-right: 20px;">
                    {{ indianFormat(detail.qty)}}
                  </td>

                  <td style="padding: 0px;text-align: right;padding-right: 20px;">
                    {{ indianFormat(detail.taxable_amt)}}
                  </td>

                  <td style="padding: 0px;text-align: right;padding-right: 20px;">
                    {{ indianFormat(detail.igst_amt)}}
                  </td>
                  <td style="padding: 0px;text-align: right;padding-right: 20px;">
                    {{ indianFormat(detail.cgst_amt)}}
                  </td>
                  <td style="padding: 0px;text-align: right;padding-right: 20px;">
                    {{ indianFormat(detail.sgst_amt)}}
                  </td>

                  <td style="padding: 0px;text-align: right;padding-right: 20px;">
                    {{ indianFormat(detail.net_amt)}}
                  </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right" ><span >{{ rowQtyTotal }}</span></td>
                  <td class="text-right" >&#8377;<span >{{ indianFormat(rowTaxableTotal) }}</span></td>
                  <td class="text-right" >&#8377;<span >{{ indianFormat(rowIgstTotal) }}</span></td>
                  <td class="text-right" >&#8377;<span >{{ indianFormat(rowCgstTotal) }}</span></td>
                  <td class="text-right" >&#8377;<span >{{ indianFormat(rowSgstTotal) }}</span></td>
                  <td class="text-right" >&#8377;<span >{{ indianFormat(rowAmountTotal) }}</span></td>


                </tr>
                </tfoot>
              </table>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <div class="form-group form-group-material">
                <label class="control-label font-weight-semibold">Narration</label>
                <textarea class="form-control" v-if="invoice" v-model="invoice.remarks" readonly> </textarea>
              </div>
            </div>


            <div class="col-md-6">
              <h3 style="font-size: 48px;color: blue">
                &#8377;<span style="padding-right: 15px;">{{ indianFormat( rowAmountTotal + parseFloat(invoice.round_off) ) }}</span>
              </h3>
            </div>

            <div class="col-md-3 text-right">
              <div class="form-group row">
                <label class="col-form-label col-md-4 font-weight-semibold">Round Off</label>
                <div class="col-md-8">
                  <input type="number" class="form-control text-right" v-if="invoice" v-model="invoice.round_off" readonly>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>`,
      }
    },
    created(){
      this.$data.voucher.pay_date = moment().format('YYYY-MM-DD');
    },
    beforeMount(){
      let self = this;
      this.invoice.list.forEach(function (det) {
        self.rowQtyTotal += det.qty;
        self.rowIgstTotal += det.igst_amt;
        self.rowCgstTotal += det.cgst_amt;
        self.rowSgstTotal += det.sgst_amt;
        self.rowTaxableTotal += det.taxable_amt;
        self.rowAmountTotal += det.net_amt;
      });
      this.invtype = transactionTypeFormatter(this.invoice.type);
      this.voucher.amount = this.invoice.net_amount;
    },
    mounted(){
      const self = this;
      $('#divinvoice').slideUp();
      this.loadLedgers();

      // Collapse card
      let _cardActionCollapse = function() {
        var $cardCollapsedClass = $('.card-collapsed');

        // Hide if collapsed by default
        $cardCollapsedClass.children('.card-header').nextAll().hide();

        // Rotate icon if collapsed by default
        $cardCollapsedClass.find('[data-action=collapse]').addClass('rotate-180');

        // Collapse on click
        $('.card [data-action=collapse]:not(.disabled)').on('click', function (e) {
          var $target = $(this),
            slidingSpeed = 150;

          e.preventDefault();
          $target.parents('.card').toggleClass('card-collapsed');
          $target.toggleClass('rotate-180');
          $target.closest('.card').children('.card-header').nextAll().slideToggle(slidingSpeed);
        });
      };

      _cardActionCollapse();


    },
    methods:{
      dateFormat(val){
        return moment(val).format('dddd MMM DD YYYY');
      },
      indianFormat(val){
        return parseFloat(val).toFixed(2);
      },
      weightFormat(val){
        return parseFloat(val).toFixed(3);
      },
      loadLedgers(){
        let self = this;

        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };


        //fetch the Ledgers
        self.$data.ledgers = [];
        fetch(`${process.env.VUE_APP_ROOT_API}v1/accounts/ledger/groups/12,22,23/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted){
            if(_.isArray(resp.data)){
              self.$data.ledgers = resp.data;
            }
          }else{
            swal (  {title:"Oops" ,  text: resp.message,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });

      },
      closeThis(){
        this.$emit('sale_invoice_priview_closed');
      },
      print(){

        let printContents = document.getElementById("printarea").innerHTML;

        var mywindow = window.open('', 'my div', 'height="80%",width="80%"');
        mywindow.document.write('<html><head><title>my div</title>');
        mywindow.document.write('<style>');
        mywindow.document.write(`.clearfix:after {
    content: "";
    display: table;
    clear: both;
  }

  a {
    color: #5D6975;
    text-decoration: underline;
  }

  body {
    position: relative;
    width: 21cm;
    height: 29.7cm;
    margin: 0 auto;
    color: #001028;
    background: #FFFFFF;
    font-family: Arial, sans-serif;
    font-size: 12px;
    font-family: Arial;
  }

  header {
    padding: 10px 0;
    margin-bottom: 30px;
  }

  #logo {
    text-align: center;
    margin-bottom: 10px;
  }

  #logo img {
    width: 90px;
  }

  h1 {
    border-top: 1px solid  #5D6975;
    border-bottom: 1px solid  #5D6975;
    color: #5D6975;
    font-size: 2.4em;
    line-height: 1.4em;
    font-weight: normal;
    text-align: center;
    margin: 0 0 20px 0;
  }

  #project {
    float: left;
  }

  #project span {
    color: #5D6975;
    text-align: right;
    width: 52px;
    margin-right: 10px;
    display: inline-block;
    font-size: 0.8em;
  }

  #company {
    float: right;
    text-align: right;
  }

  #project div,
  #company div {
    white-space: nowrap;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 10px;
  }

  table tr:nth-child(2n-1) td {
    background: #F5F5F5;
  }

  table th,
  table td {
    text-align: center;
  }

  table th {
    padding: 1px 20px;
    color: #5D6975;
    border-bottom: 1px solid #C1CED9;
    white-space: nowrap;
    font-weight: normal;
  }

  table .service,
  table .desc {
    text-align: left;
  }

  table td {
    padding: 1px;
    text-align: right;
  }

  table td.service,
  table td.desc {
    vertical-align: top;
  }

  table td.unit,
  table td.qty,
  table td.total {
    font-size: 1.2em;
  }

  table td.grand {
    border-top: 1px solid #5D6975;;
  }

  #notices .notice {
    color: #5D6975;
    font-size: 1.2em;
  }

  footer {
    color: #5D6975;
    width: 100%;
    height: 30px;
    position: absolute;
    bottom: 0;
    border-top: 1px solid #C1CED9;
    padding: 8px 0;
    text-align: center;
  }`);
        mywindow.document.write('</style>');
        mywindow.document.write('</head><body >');
        mywindow.document.write(printContents);
        mywindow.document.write('</body></html>');

        mywindow.print();
        mywindow.close();

        return true;

      },

    }
  }
</script>

<style scoped>
  .clearfix:after {
    content: "";
    display: table;
    clear: both;
  }

  a {
    color: #5D6975;
    text-decoration: underline;
  }

  body {
    position: relative;
    width: 21cm;
    height: 29.7cm;
    margin: 0 auto;
    color: #001028;
    background: #FFFFFF;
    font-family: Arial, sans-serif;
    font-size: 12px;
    font-family: Arial;
  }

  header {
    padding: 10px 0;
    margin-bottom: 30px;
  }

  #logo {
    text-align: center;
    margin-bottom: 10px;
  }

  #logo img {
    width: 90px;
  }

  h1 {
    border-top: 1px solid  #5D6975;
    border-bottom: 1px solid  #5D6975;
    color: #5D6975;
    font-size: 2.4em;
    line-height: 1.4em;
    font-weight: normal;
    text-align: center;
    margin: 0 0 20px 0;
  }

  #project {
    float: left;
  }

  #project span {
    color: #5D6975;
    text-align: right;
    width: 52px;
    margin-right: 10px;
    display: inline-block;
    font-size: 0.8em;
  }

  #company {
    float: right;
    text-align: right;
  }

  #project div,
  #company div {
    white-space: nowrap;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 10px;
  }

  table tr:nth-child(2n-1) td {
    background: #F5F5F5;
  }

  table th,
  table td {
    text-align: center;
  }

  table th {
    padding: 1px 20px;
    color: #5D6975;
    border-bottom: 1px solid #C1CED9;
    white-space: nowrap;
    font-weight: normal;
  }

  table .service,
  table .desc {
    text-align: left;
  }

  table td {
    padding: 1px  ;
    text-align: right;
  }

  table td.service,
  table td.desc {
    vertical-align: top;
  }

  table td.unit,
  table td.qty,
  table td.total {
    font-size: 1.2em;
  }

  table td.grand {
    border-top: 1px solid #5D6975;;
  }

  #notices .notice {
    color: #5D6975;
    font-size: 1.2em;
  }

  footer {
    color: #5D6975;
    width: 100%;
    height: 30px;
    position: absolute;
    bottom: 0;
    border-top: 1px solid #C1CED9;
    padding: 8px 0;
    text-align: center;
  }
</style>
